.next-steps .fa-link {
  margin-right: 5px;
}

.btn {
  border-radius: 7px;
}
/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.btn-primary{
  background-color: #38C7BB;
  border-radius: 7px;
  border-color: transparent;
}

.btn-primary:hover{
  background-color: rgb(60, 216, 203);
  border-radius: 7px;
  border-color: transparent;
}

.form-control img {
  max-width: 85%;
  border: 3px dotted #dbd4d4;
}

option::after {
  content: 'bb';
  border: 1px solid red;
  width: 10px;
  height: 10px;
}


/* ///// */
.btn-login{
  background-color: transparent;
  color: rgb(169,179,200);
  border-radius: 7px;
  border-color: transparent;
  display: flex;
  justify-content: center;
  font-weight: 600;
}
.btn-login:focus{
  border: transparent;
}
.btn-login:hover{
  background-color: transparent;
  color: rgb(169,179,200);
  border-radius: 7px;
  border-color: transparent;
}

.btn-login:hover .nav-animate{
  animation-name:arrow;
  animation-duration:1s;
  animation-timing-function:linear;
  animation-delay:0s;
  animation-iteration-count:infinite;
  animation-direction:normal;
  animation-play-state:running;
}

.ascending {
  transform: rotate(180deg);
  opacity: 1 !important;
}
.descending{
  opacity: 1 !important;

}
.rotate {
  opacity: 0.2;
}


@keyframes arrow {
  0%   { 
      left:0; 
  }
  75%  { 
      left: -10px; 
  }
  100% { 
      left: 0; 
  }
}

/* ///// */
