.btnPrimary{
    background-color: #38C7BB;
    border-radius: 8px;
    border-color: transparent;
    display: flex;
    justify-content: center;
}

.btnPrimary:hover{
    background-color: rgb(60, 216, 203);
    border-radius: 8px;
    border-color: transparent;
}

.navCircle {
    margin-right: 9px;
    display: inline-block;
    width: 12px;
    position: relative;
    height: 24px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border: 1.67px solid rgba(169, 179, 200, 0.863);
    border-left: 0;
}

.navArrow {
    position: absolute;
    left: -50%;
    top: 50%;
    transform: translate(-60%, -50%);
    font-size: 29px;
}

