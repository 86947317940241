.helperSlogan {
    margin-top: 4rem;
}
.dashHelper {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.height60 {
    min-height: 64px;
}
.helperBox {
    cursor: pointer;
    width: 30%;
    border-radius: 20px;
    box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
}

.bounce {
    display: inline-block;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    font-size: 33px;
    animation-name: bounce;
    animation-timing-function: ease;
}
.colorOne {
    background:rgba(7, 174, 235, 0.5);
    transition: all .15s ease-in-out;
}
.colorOne:hover {
    background:rgba(0, 187, 255, 0.5);
}
.colorTwo {
    background:rgb(0, 184, 157, 0.5);
    transition: all .15s ease-in-out;
}
.colorTwo:hover {
    background:rgba(0, 206, 175, 0.5);
}
.colorThree {
    background:rgb(245, 159, 47);
    transition: all .15s ease-in-out;
}
.colorThree:hover {
    background:rgb(255, 176, 72);
}
@keyframes bounce {
    0%   { 
        transform: scale(1,1)    translateY(0); 
    }
    10%  { 
        transform: scale(1.1,.9) translateY(0); 
    }
    30%  { 
        transform: scale(.9,1.1) translateY(-20px); 
    }
    50%  { 
        transform: scale(1,1)    translateY(0); 
    }
    57%  { 
        transform: scale(1,1)    translateY(-10px); 
    }
    64%  { 
        transform: scale(1,1)    translateY(0); 
    }
    100% { 
        transform: scale(1,1)    translateY(0); 
    }
}

@media (max-width: 680px) {
    .helperBox {
        width: 100%;
    }
    .helperBox {
        margin-top: 2rem;
    }
}