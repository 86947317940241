.search {
    float: right;
}
.licenseSearch {
    margin-bottom: 1rem;
    margin-right: .5rem;
    border: 1px solid #d7dbe0;
}
.icon {
    font-size: 1.5rem;
}

h6 {
    color:#b1b0b0;
    width: 200px;
    display: inline-block;
}

.usage {
    margin: 2rem 0;
}
/* progress bar */
.progressGrey {
    background-color: rgb(211, 208, 208);
    height: 20px;
    border-radius: 10px;
  }
  .progressBlue {
    background-color: rgba(0, 138, 124, 0.698);
    height: 20px;
    border-radius: 10px;
    animation: progressBar 1.8s ease-in-out;
    animation-fill-mode:both; 
  }
  @keyframes progressBar {
    0% { width: 0; }
    100% { }
  }